














































































































import { InputSetups } from '@/mixins/input-setups';
import MobileLandscapeTriggerLayout from "@/components/MobileLandscapeTriggerLayout.vue";
import InviteLinksStatChart from "@/components/chat/InviteLinks/InviteLinksStatChart.vue";
import { findOne, getInviteLinksStat, selectOptionsFromLinks } from "@/includes/logic/InviteLinks/InviteLinksHelper";
import { InviteLinkWithStat } from "@/includes/logic/InviteLinks/types";
import AccumulatorChart from "@/components/chat/InviteLinks/charts/AccumulatorChart.vue";
import EnterChart from "@/components/chat/InviteLinks/charts/EnterChart.vue";
import RepeatEnterChart from "@/components/chat/InviteLinks/charts/RepeatEnterChart.vue";
import LeaveUniqueChart from "@/components/chat/InviteLinks/charts/LeaveUniqueChart.vue";
import KickUniqueChart from "@/components/chat/InviteLinks/charts/KickUniqueChart.vue";

import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi/index';
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue';

import Component from "vue-class-component";
import moment from "moment";
import { Mixins, Watch } from "vue-property-decorator";
import { get } from "lodash";

@Component({
  methods: { get },
  'components': {
    PageTitle,
    InviteLinksStatChart,
    MobileLandscapeTriggerLayout,
    EmptyData,
    LeaveUniqueChart,
    AccumulatorChart,
    EnterChart,
    RepeatEnterChart,
    KickUniqueChart
  },
  data() {
    return {
      moment
    }
  }
})
export default class LinkStatistics extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  date = {
    from: moment().add(-30, "days").format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD")
  }

  selectedLinks: Array<string | null> = []

  linksToProcess: Array<InviteLinkWithStat> = []

  inviteLinks: Array<InviteLinkWithStat> | null = null

  @Watch('selectedLinks')
  onTagsChange(): void {
    if (this.inviteLinks) {
      this.updateLinksToProcess(this.inviteLinks)
    }
  }

  @Watch('$route.params.CHAT_ID')
  onChatChange(): void {
    this.selectedLinks = []

    this.getStatistics()
  }

  get linksOptions() {
    return (this.inviteLinks && selectOptionsFromLinks(this.inviteLinks, this.$i18n)) || []
  }

  getLinksStat() {
    return getInviteLinksStat(this.$store.getters.chatId, {
      interval: 'hour',
      from: this.date.from,
      to: this.date.to
    })
  }

  updateLinksToProcess(links: Array<InviteLinkWithStat>) {
    this.linksToProcess = links!.filter(l => this.selectedLinks.includes(l.link.code!) || this.selectedLinks.includes(null))
  }

  getStatistics() {
    this.$baseTemplate.loader.open()

    this.getLinksStat()
      .then((res) => {
        this.inviteLinks = res

        this.updateLinksToProcess(res)
      })
      .finally(() => this.$baseTemplate.loader.close())
  }

  mounted() {
    this.$baseTemplate.saveButton.hide();
    this.$baseTemplate.loader.open()

    this.getLinksStat()
      .then((res) => {
        const code = this.$route.query.code;

        if (code) {
          const link = findOne(res, code.toString())

          if (link) {
            this.selectedLinks.push(link.code!)
          }
        }

        if (res.length === 1) {
          const firstLink = res[0];

          this.selectedLinks.push(firstLink.link.code ? firstLink.link.code : null)
        }

        this.inviteLinks = res
        this.updateLinksToProcess(res)
      })
      .finally(() => this.$baseTemplate.loader.close())

  }

  destroyed() {
    this.$baseTemplate.saveButton.show();
  }
}
