


















import { InviteLinkWithStat } from "@/includes/logic/InviteLinks/types";

import { ApexChartLocales, ApexChartSeries } from "piramis-base-components/src/shared/types/ApexChartSeries.types";
import { seriesByMetricInList } from "piramis-base-components/src/shared/utils/seriesByMetricInList";
import { fromToTicks } from "piramis-base-components/src/shared/utils/fromToTicks";

import VueApexCharts from "vue-apexcharts";
import { ApexOptions } from "apexcharts";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {
    VueApexCharts
  }
})
export default class KickUniqueChart extends Vue {
  @Prop() links!: Array<InviteLinkWithStat>

  @Prop() from !: string

  @Prop() to !: string

  @Watch('links')
  onchange() {
    this.calcChartSeries()
  }

  chartLoading = false

  series: ApexChartSeries | null = null

  get ticks() {
    return fromToTicks(this.from, this.to, {
      format: 'YYYY-MM-DD 00:00:00'
    })
  }

  options: ApexOptions | null = null

  calcChartSeries() {
    this.chartLoading = true

    const values = this.links.map((link) => {
      return seriesByMetricInList(this.$i18n, link.stat!, [ 'kick_unique_users' ], this.ticks)
        .calcSeries([
          { name: link.link.title.length ? link.link.title : 'Без ссылки' }
        ])
        .then((res) => res)
    })

    Promise.all(values)
      .then(res => {
        this.series = res.flat()

        this.options = {
          chart: {
            type: 'area',
            toolbar: {
              show: true
            },
            zoom: {
              enabled: true
            },
            animations: {
              enabled: false
            },
            defaultLocale: this.$i18n.locale,
            locales: ApexChartLocales,
          },
          stroke: {
            curve: 'smooth',
            width: 2.5,
          },
          dataLabels: {
            enabled: false
          },
          yaxis: {
            labels: {
              formatter(val: number, opts?: any): string | string[] {
                return val.toFixed()
              }
            }
          },
          xaxis: {
            type: 'datetime',
            categories: this.ticks.timeTicks,
            tooltip: {
              enabled: false
            }
          }
        }
      })
      .catch(() => this.series = null)
      .finally(() => this.chartLoading = false)
  }

  mounted() {
    this.calcChartSeries()
  }
}
