
















































































































import Vue from 'vue';
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";

@Component({
  'components': {
    VueApexCharts
  }
})
export default class InviteLinksStatChart extends Vue {
  @Prop() statisticsData!: any;
}
